import { isProduction } from "@libry-content/common";
import { isValidLanguageCode, validateLocaleOrFallbackToDefault } from "@libry-content/localization";
import * as Sentry from "@sentry/nextjs";
import { IntlConfig } from "next-intl"; // eslint-disable-line no-restricted-imports
// eslint-disable-next-line no-restricted-imports
import { getRequestConfig } from "next-intl/server";
import { notFound } from "next/navigation";

export type IntlMessages = IntlConfig["messages"];

export const getMessages = async (locale: any) => {
  const validatedLocale = validateLocaleOrFallbackToDefault(locale);
  return (await import(`../locales/${validatedLocale}/common.json`)).default as IntlMessages;
};

export const nextIntlErrorLogger = (error: any) => {
  const { originalMessage, message } = error;
  const errorMessage = `NextIntl error: ${originalMessage ?? message} Url: "${
    typeof window !== "undefined" ? window.location.pathname : "unknown" // bruker pathname for å unngå å logge query params som kan inneholde sensitiv informasjon
  }"`;

  console.log(`%c 💬 ${errorMessage} `, "background: red; color: white; padding: 5px;");

  if (isProduction) {
    Sentry.captureException(errorMessage);
  }
};

export default getRequestConfig(async ({ locale }) => {
  // Validate that the incoming `locale` parameter is valid
  if (!isValidLanguageCode(locale)) notFound();

  return {
    messages: await getMessages(locale),
    locale,
    timeZone: "Europe/Oslo",
    onError: nextIntlErrorLogger,
  };
});
